define("discourse/plugins/discourse-asana-plugin/admin/controllers/admin-dashboard-international", ["exports", "@ember/object", "admin/controllers/admin-dashboard-tab", "I18n"], function (_exports, _object, _adminDashboardTab, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminDashboardInternationalController extends _adminDashboardTab.default {
    language = 160;
    languageOptions = (() => [{
      id: 160,
      name: _I18n.default.t("discourse_asana.admin.international.english")
    }, {
      id: 77,
      name: _I18n.default.t("discourse_asana.admin.international.japanese")
    }, {
      id: 39,
      name: _I18n.default.t("discourse_asana.admin.international.german")
    }, {
      id: 40,
      name: _I18n.default.t("discourse_asana.admin.international.french")
    }, {
      id: 69,
      name: _I18n.default.t("discourse_asana.admin.international.spanish")
    }, {
      id: 73,
      name: _I18n.default.t("discourse_asana.admin.international.portuguese")
    }])();
    get languageName() {
      return this.languageOptions.find(x => x.id === this.language).name;
    }
    static #_ = (() => dt7948.n(this.prototype, "languageName", [(0, _object.computed)("language")]))();
    get filters() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
        customFilters: {
          category: this.language,
          include_subcategories: true
        }
      };
    }
    static #_2 = (() => dt7948.n(this.prototype, "filters", [(0, _object.computed)("startDate", "endDate", "language")]))();
  }
  _exports.default = AdminDashboardInternationalController;
});