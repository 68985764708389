define("discourse/plugins/discourse-asana-plugin/admin/controllers/admin-dashboard-leaders", ["exports", "@ember/object", "admin/controllers/admin-dashboard-tab"], function (_exports, _object, _adminDashboardTab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminDashboardLeadersController extends _adminDashboardTab.default {
    get filters() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
        customFilters: {
          group: "forum_leaders"
        }
      };
    }
    static #_ = (() => dt7948.n(this.prototype, "filters", [(0, _object.computed)("startDate", "endDate")]))();
    get filtersTipsCategory() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
        customFilters: {
          category: "forum_leaders_tips"
        }
      };
    }
    static #_2 = (() => dt7948.n(this.prototype, "filtersTipsCategory", [(0, _object.computed)("startDate", "endDate")]))();
  }
  _exports.default = AdminDashboardLeadersController;
});