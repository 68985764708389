define("discourse/plugins/discourse-asana-plugin/admin/controllers/admin-dashboard-ambassadors", ["exports", "@ember/object", "admin/controllers/admin-dashboard-tab"], function (_exports, _object, _adminDashboardTab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminDashboardAmbassadorsController extends _adminDashboardTab.default {
    get filters() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
        customFilters: {
          group: "ambassadors"
        }
      };
    }
    static #_ = (() => dt7948.n(this.prototype, "filters", [(0, _object.computed)("startDate", "endDate")]))();
  }
  _exports.default = AdminDashboardAmbassadorsController;
});